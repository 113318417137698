import React from "react";
import { Link } from "gatsby";
import "./button.sass";

export const Button = ({
  center,
  expand,
  right,
  onClick,
  title,
  marginTop,
  marginTopLarge,
  marginBottom,
  marginBottomLarge,
  type,
}) => (
  <div
    className={
      (center ? " button-center" : "") +
      (right ? " button-right" : "") +
      (marginTop ? " button-margin-top" : "") +
      (marginTopLarge ? " button-margin-top-large" : "") +
      (marginBottom ? " button-margin-bottom" : "") +
      (marginBottomLarge ? " button-margin-bottom-large" : "") +
      (expand ? " button-expand" : "")
    }
  >
    <button
      className={"button" + (center ? " button-center" : "")}
      onClick={onClick}
      type={type ? type : ""}
    >
      {title}
    </button>
  </div>
);

export const LinkButton = ({
  center,
  right,
  title,
  to,
  marginTop,
  marginBottom,
  marginBottomLarge,
}) => (
  <div
    className={
      (center ? " button-center" : "") +
      (right ? " button-right" : "") +
      (marginTop ? " button-margin-top" : "") +
      (marginBottom ? " button-margin-bottom" : "") +
      (marginBottomLarge ? " button-margin-bottom-large" : "")
    }
  >
    <Link to={to}>
      <button className={"button"}>{title}</button>
    </Link>
  </div>
);
